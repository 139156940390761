var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { TitleSecondary } from '@src/components/styled/Typography';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn, SurfaceColumn, alignToCenter } from '@src/components/styled';
import { useProfileOutletContext } from '@features/profile/profile-info';
import useProfileNavigation, { matchPathToPortfolioTab } from '@features/profile/profile-navigation';
import { useGetCurrentProfilePortfoliosQuery } from '@shared/api/portfolio';
import { mapClosedPortfoliosListFromResponse, shouldFilterByAssessmentPassed } from '@entities/portfolio';
import { PortfoliosEmptyView } from '@features/portfolio/portfolio-empty-view';
import { getPortfolioTabs } from '../lib';
import PortfoliosList from './PortfoliosList';
const ProgressErrorContainer = alignToCenter(ContainerColumn);
const Content = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const EmptyViewContainer = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
    border: 'none',
    borderRadius: 12,
    padding: `${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px 
              ${props.theme.spacing_sizes.m * 3}px ${props.theme.spacing_sizes.l}px`,
}));
const ProfileClosedPortfolios = () => {
    const { portfolioId: portfolioIdParam } = useParams();
    const { profileId, nickname, profileType, isMyProfile = false, isVerifiedTrader, } = useProfileOutletContext();
    const { language } = useAppSelector(state => state.app);
    const { user } = useAppSelector(state => state.user);
    const { pathname: locationPathname } = useLocation();
    const { navigateToProfileTab, navigateToPortfolioTab, } = useProfileNavigation();
    const portfolioId = portfolioIdParam || undefined;
    const { currentData: portfoliosList = [], isFetching: isFetchingPortfolios, isError: isWalletsError, status: portfoliosStatus, } = useGetCurrentProfilePortfoliosQuery({
        profileId: profileId,
        assessmentPassed: shouldFilterByAssessmentPassed({
            portfolioProfileId: profileId,
            currentUser: user,
            isVerifiedTrader,
        }),
    }, {
        selectFromResult: (_a) => {
            var { currentData } = _a, rest = __rest(_a, ["currentData"]);
            return (Object.assign(Object.assign({}, rest), { currentData: mapClosedPortfoliosListFromResponse(currentData || []) }));
        }
    });
    const arePortfoliosFetching = portfoliosStatus === QueryStatus.pending;
    const arePortfoliosFetched = portfoliosStatus !== QueryStatus.uninitialized &&
        portfoliosStatus !== QueryStatus.pending;
    const arePortfoliosEmpty = arePortfoliosFetched && portfoliosList.length === 0;
    const displayPortfoliosList = !arePortfoliosEmpty || arePortfoliosFetching;
    // TODO: discuss
    const isFetching = isFetchingPortfolios;
    const isError = isWalletsError;
    const onPortfolioSelect = (selectedPortfolioId) => {
        if (selectedPortfolioId === portfolioId) {
            navigateToProfileTab('closed_portfolios', { params: { nickname } });
        }
        else {
            navigateToPortfolioTab('information_closed', {
                params: {
                    nickname,
                    portfolioId: String(selectedPortfolioId),
                },
            });
        }
    };
    const portfolioTabs = getPortfolioTabs(language);
    const currentTab = useMemo(() => matchPathToPortfolioTab(locationPathname), [locationPathname]);
    const onTabChange = (selectedTab) => {
        if (!portfolioId) {
            return;
        }
        navigateToPortfolioTab(selectedTab, {
            params: {
                nickname,
                portfolioId: String(portfolioId),
            },
        });
    };
    useEffect(() => {
        if (profileType && profileType === 'CLIENT') {
            navigateToProfileTab('portfolios');
        }
    }, [profileType, navigateToProfileTab]);
    return (_jsx(ContainerColumn, { children: _jsxs(_Fragment, { children: [_jsx(Content, { children: (arePortfoliosEmpty) && (_jsx(EmptyViewContainer, { children: _jsx(PortfoliosEmptyView, { profileType: profileType, isMyProfile: isMyProfile, haveExchanges: Boolean(portfoliosList.length > 0) }) })) }), displayPortfoliosList &&
                    (_jsx(PortfoliosList, { nickname: nickname, portfolioId: portfolioId, portfoliosList: portfoliosList, portfolioTabs: portfolioTabs, onPortfolioSelect: onPortfolioSelect, profileId: profileId, currentTab: currentTab, onTabChange: onTabChange, selectedPortfolio: portfolioId, isMyProfile: isMyProfile, isLoading: isFetchingPortfolios })), !isFetching &&
                    isError &&
                    (_jsx(ProgressErrorContainer, { children: _jsx(TitleSecondary, { children: "error" }) }))] }) }));
};
export default ProfileClosedPortfolios;
