import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
const GeneralPortfolioInformationTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.general_portfolio_information.title',
    lang: language,
});
const CurrentPositionsTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.current_positions',
    lang: language,
});
const PositionsHistoryTitle = ({ language }) => Trans({
    ns: 'common',
    i18nKey: 'pages.portfolios.history',
    lang: language,
});
// eslint-disable-next-line import/prefer-default-export
export const getPortfolioTabs = (language) => [
    {
        value: 'information_closed',
        label: _jsx(GeneralPortfolioInformationTitle, { language: language }),
    },
    {
        value: 'current_positions_closed',
        label: _jsx(CurrentPositionsTitle, { language: language }),
    },
    {
        value: 'history_closed',
        label: _jsx(PositionsHistoryTitle, { language: language }),
    },
];
