import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PortfoliosCarousel } from '@features/portfolio/portfolio-carousel';
import { IsDefined } from '@shared/lib/js-ts';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn } from '@src/components/styled';
const Content = styled(ContainerColumn)((props) => ({
    gap: props.theme.spacing_sizes.l,
}));
const TabsStyled = styled((Tabs))(() => ({
    borderBottom: '1px solid #E6E8EB',
    '& .MuiTabs-flexContainer': {
        width: '100%',
        justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
        flex: 1,
    },
}));
const TabContent = styled(ContainerColumn)();
const PortfoliosList = ({ portfoliosList, onPortfolioSelect, onTabChange = undefined, selectedPortfolio = undefined, currentTab = undefined, portfolioTabs, profileId, nickname, portfolioId = undefined, isMyProfile, isLoading = undefined, }) => {
    const { t } = useTranslation();
    return (_jsxs(Content, { children: [_jsx(PortfoliosCarousel, { title: t('profile.tabs.closed_portfolios'), portfolios: portfoliosList, selectedPortfolioId: selectedPortfolio, onSelectPortfolioId: onPortfolioSelect, showCreatePortfolioBtn: isMyProfile, isLoading: isLoading }), IsDefined(currentTab) && (_jsxs(_Fragment, { children: [_jsx(TabsStyled, { tabs: portfolioTabs, activeTab: currentTab, onChange: onTabChange }), _jsx(TabContent, { children: _jsx(Outlet, { context: {
                                profileId,
                                nickname,
                                portfolioId,
                                isClosedTabs: true,
                            } }) })] }))] }));
};
export default PortfoliosList;
